





























import { Project } from '@/api/projects/project.model';
import { User } from '@/api/users/user.class';
import { Filter } from '@/shared/types/filter.class';
import Vue from 'vue';
import Component from 'vue-class-component';

import { namespace } from 'vuex-class';
import { LoginResult } from '../api/auth/login-result.class';
import { Login } from '../api/auth/login.class';

const projectsModule = namespace('projects');
const authModule = namespace('auth');
const appModule = namespace('app');

// The @Component decorator indicates the class is a Vue component
@Component({})
export default class SelectProject extends Vue {
  @appModule.Getter('isLoading')
  isLoading!: (id: string) => boolean;

  @projectsModule.Getter('all')
  projects!: Project[];

  @appModule.Mutation('addLoader')
  addLoader!: (id: string) => void;

  @appModule.Mutation('removeLoader')
  removeLoader!: (id: string) => void;

  @authModule.Mutation('setProject')
  setProject!: (project: Project) => void;

  @projectsModule.Action('fetchAll')
  fetchProjects!: (filter?: Filter) => Promise<Project[]>;

  async selectProject(project: Project) {
    this.addLoader('select');
    try {
      this.setProject(project);
      this.$router.push({ path: '/' });
    } finally {
      this.removeLoader('select');
    }
  }

  async created() {
    this.addLoader('select');
    this.setProject(undefined);
    await this.fetchProjects(new Filter([], { name: 1 }));
    this.removeLoader('select');
  }
}
